/* ── Desktop (Original) Styles ── */

/* COMMUNITY CTA BACKGROUND */
.community-cta-background {
    background-image: url("https://cdn.animaapp.com/projects/678a985e0cccaf0892a06cc5/releases/678a99f1cefd91045472e2fe/img/mask-group.png");
    background-size: 100% 100%;
    width: 1132px;
    height: 728px;
    /* Originally absolutely positioned; now we center it in the flow */
    margin: 2rem auto;
    position: relative;
  }
  
  /* COMMUNITY CTA content */
  .community-cta {
    /* Desktop: use the original absolute positioning to match the design */
    position: absolute;
    width: 627px;
    height: 376px;
    top: 176px;
    left: 254px;
  }
  
  /* COMMUNITY CTA title */
  .community-cta-title {
    color: var(--text);
    font-family: "Quicksand", sans-serif;
    font-size: 56px;
    font-weight: 700;
    line-height: 68.9px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
  }
  
  /* COMMUNITY CTA avatars */
  .community-avatars {
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: 270px;
    left: 69px;
  }
  
  .avatar-img {
    width: 72px;
    height: 72px;
    position: relative;
    margin-left: -12px;
  }
  
  /* COMMUNITY CTA subtitle */
  .community-cta-subtitle {
    color: var(--text);
    font-family: "Quicksand", sans-serif;
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    opacity: 0.7;
    position: absolute;
    top: 93px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 800px;
    padding: 0 40px;
    box-sizing: border-box;
    overflow-wrap: break-word;
  }
  
  /* Join Now button */
  .join-now-button-wrapper {
    position: absolute;
    top: 326px;
    left: 220px;
    background-color: var(--ocean-blue);
    border-radius: 12px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .join-now-button-text {
    color: #ffffff;
    font-family: "Quicksand", sans-serif;
    font-size: 24px;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
  }
  
  /* ── Responsive Adjustments ── */
  
  /* For viewports 768px and below */
  @media (max-width: 768px) {
    /* Scale down the overall container while preserving the aspect ratio */
    .community-cta-background {
      width: 90%;
      /* Let the height adjust automatically based on content */
      height: auto;
      background: url("https://cdn.animaapp.com/projects/678a985e0cccaf0892a06cc5/releases/678a99f1cefd91045472e2fe/img/mask-group.png")
        no-repeat center;
      background-size: contain;
      margin: 1rem auto;
      padding: 1rem; /* provides a neat boundary */
    }
    
    /* Make inner content flow naturally by removing absolute positioning */
    .community-cta {
      position: relative;
      width: 100%;
      height: auto;
      top: 0;
      left: 0;
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .community-cta-title {
      position: static;
      font-size: clamp(32px, 6vw, 48px);
      margin-bottom: 0.5rem;
      white-space: normal;
      font-family: "Quicksand", sans-serif;
    }
    
    .community-avatars {
      position: static;
      margin: 0.5rem 0;
      justify-content: center;
    }
    
    .community-cta-subtitle {
      position: static;
      font-size: clamp(16px, 4vw, 22px);
      padding: 0 10px;
      margin-bottom: 1rem;
      font-family: "Quicksand", sans-serif;
    }
    
    .join-now-button-wrapper {
      position: static;
    }
    
    .avatar-img {
      width: 50px;
      height: 50px;
      margin-left: -6px;
    }
  }
  
  /* For viewports 480px and below */
  @media (max-width: 480px) {
    .community-cta-title {
      font-size: clamp(28px, 6vw, 42px);
      font-family: "Quicksand", sans-serif;
    }
    .community-cta-subtitle {
      font-size: clamp(14px, 4vw, 20px);
      padding: 0 8px;
      font-family: "Quicksand", sans-serif;
    }
    .join-now-button-text {
      font-size: clamp(16px, 5vw, 20px);
      font-family: "Quicksand", sans-serif;
    }
    .avatar-img {
      width: 40px;
      height: 40px;
      margin-left: -4px;
    }
  }
  