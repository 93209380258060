/* PrivacyPolicy.css */

.privacy-policy-page {
    background-color: #fdfeff;
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    font-family: "Quicksand", sans-serif;
    color: #333;
  }
  
  .privacy-policy-container {
    width: 90%;
    max-width: 800px;
  }
  
  .privacy-policy-container h1 {
    font-size: 48px;
    text-align: center;
    margin-bottom: 30px;
    font-family: "Quicksand", sans-serif;
  }
  
  .collapsible-section {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    transition: background-color 0.2s ease;
    font-family: "Quicksand", sans-serif;
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-family: "Quicksand", sans-serif;
  }
  
  .section-header h2 {
    font-size: 24px;
    margin: 0;
    font-family: "Quicksand", sans-serif;
  }
  
  .toggle-icon {
    font-size: 24px;
    font-weight: bold;
    font-family: "Quicksand", sans-serif;
  }
  
  .section-content {
    margin-top: 10px;
    font-size: 18px;
    line-height: 1.6;
    font-family: "Quicksand", sans-serif;
  }
  
  .section-content p {
    margin: 0;
    font-family: "Quicksand", sans-serif;
  }
  