/* Container for the entire Features Section */
.featuresContainer {
  padding: 2rem; /* This remains, assuming it provides adequate overall spacing */
  margin: 2rem auto;
  max-width: 972px; /* Increased from 940px to include extra side spacing */
  text-align: center;
  box-sizing: border-box;
}

/* Header section for title and subtitle */
.featuresHeader {
  margin-bottom: 2rem;
}

.featuresTitle {
  font-family: "Quicksand", sans-serif;
  font-size: clamp(32px, 5vw, 56px);
  font-weight: 700;
  line-height: 68.9px;
  text-align: center;
  margin: 0 auto;
}

.featuresSubtitle {
  font-family: "Quicksand", sans-serif;
  font-size: clamp(16px, 3vw, 26px);
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  margin: 1rem auto 2rem auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

/* Features list container using flexbox */
.featuresList {
  display: flex;
  justify-content: center;
  gap: 32px; /* Increased spacing on desktop */
  flex-wrap: nowrap; /* Force a single row on desktop */
}

/* For mobile, allow wrapping and revert gap to 24px if needed */
@media (max-width: 768px) {
  .featuresList {
    flex-wrap: wrap;
    gap: 24px;
  }
}

/* Each feature card */
.featureCard {
  position: relative;
  width: 292px;
  height: 294px;
  background: url("https://cdn.animaapp.com/projects/678a985e0cccaf0892a06cc5/releases/678a99f1cefd91045472e2fe/img/rectangle-102.svg")
    no-repeat center/100% 100%;
  border-radius: 12px;
  box-sizing: border-box;
}

/* Make the entire card clickable */
.featureLink {
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

/* Icon container (positioned absolutely within the card) */
.iconContainer {
  position: absolute;
  top: 48px;
  left: 48px;
  width: 100px;
  height: 100px;
}

/* Circle icon (for "Chat with Qarby") */
.circleIcon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* Message icon positioned over the circle */
.msgIcon {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 30px;
  left: 30px;
}

/* Alternate icon background for the Matchmaker and Garage cards */
.iconBgAlt {
  background-color: #007291;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon sizes */
.icon {
  width: 35px;
  height: 35px;
}

.customIcon {
  width: 35px;
  height: 35px;
}

/* Card title styling (positioned absolutely within the card) */
.cardTitle {
  position: absolute;
  top: 172px;
  left: 48px;
  width: 222px;
  font-family: "Quicksand", sans-serif;
  font-size: clamp(18px, 3vw, 32px);
  font-weight: 600;
  line-height: 39.4px;
  color: var(--charcoal-grey);
  text-align: left; /* Ensure text is left justified */
}

/* Arrow wrapper (positioned in the upper right corner of the card) */
.arrowWrapper {
  position: absolute;
  top: 12px;
  right: 13px;
  width: 60px;
  height: 60px;
  background-color: #424242;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

/* Arrow icon styling (using background image for the arrow) */
.featureArrowIcon {
  width: 20px;
  height: 20px;
  background: url("../assets/up_right_arrow.png") center/contain no-repeat;
}

/* ========================================================= */
/*               Media Queries for Smaller Screens         */
/* ========================================================= */
@media (max-width: 768px) {
  /* Adjust the feature card */
  .featureCard {
    width: 220px;  /* scaled down from 292px */
    height: 220px; /* scaled down from 294px */
  }

  /* Adjust the icon container */
  .iconContainer {
    top: 32px;    /* scaled down from 48px */
    left: 32px;   /* scaled down from 48px */
    width: 80px;  /* scaled down from 100px */
    height: 80px; /* scaled down from 100px */
  }

  /* Adjust the message icon */
  .msgIcon {
    top: 18px;    /* scaled down from 30px */
    left: 18px;   /* scaled down from 30px */
    width: 32px;  /* scaled down from 40px */
    height: 32px; /* scaled down from 40px */
  }

  /* Adjust the card title positioning and size */
  .cardTitle {
    top: 120px;   /* scaled down from 172px */
    left: 32px;   /* scaled down from 48px */
    width: 196px; /* scaled down from 222px */
    font-size: clamp(16px, 3vw, 28px); /* adjusted clamp values */
  }

  /* Adjust the arrow wrapper and arrow icon */
  .arrowWrapper {
    top: 6px;     /* scaled down from 12px */
    right: 6px;   /* scaled down from 13px */
    width: 50px;  /* scaled down from 60px */
    height: 50px; /* scaled down from 60px */
  }

  .featureArrowIcon {
    width: 16px;  /* scaled down from 20px */
    height: 16px; /* scaled down from 20px */
  }
}
