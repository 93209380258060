/* src/components/FAQSection.module.css */

.faqContainer {
    padding: 2rem;
    margin: 2rem auto;
    max-width: 700px;
    font-family: "Quicksand", sans-serif;
  }
  
  .heading {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1.5rem;
    font-family: "Quicksand", sans-serif;
  }
  
  .faqItem {
    background-color: #e3eef3;
    border-radius: 12px;
    padding: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .faqItemOpen {
    padding-bottom: 1rem;
  }
  
  .questionRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .questionText {
    font-size: 1.125rem;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
  }
  .arrowCircle {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    background-color: #424242;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;  /* Prevents the circle from shrinking */
  }
  
.arrowIcon {
  width: 20px !important;
  height: 20px !important;
  flex-shrink: 0;
  /* Removed display: block; so that the flex container remains intact */
  fill: #fff;
  transition: transform 0.2s ease;
}

.arrowIconRotated {
  transform: rotate(180deg);
}

.arrowIcon {
  width: 20px !important;
  height: 20px !important;
  flex-shrink: 0;
  /* Removed display: block; so that the flex container remains intact */
  fill: #fff;
  transition: transform 0.2s ease;
}



  .answer {
    font-size: 1rem;
    font-weight: 400;
    color: #444;
    margin-top: 0.5rem;
    line-height: 1.4;
    font-family: "Quicksand", sans-serif
  }
  