/* Container Layout */
.community-container {
  display: flex;
  background: #fff;
  height: 100vh; /* Or auto */
  font-family: "Quicksand", sans-serif;
}

/* Sidebar */
.community-sidebar {
  width: 200px;
  background: #fff; /* white */
  border-right: 1px solid #ddd;
  padding: 20px;
}

.side-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* We'll left-justify the text */
  text-align: left;
}
.side-menu li {
  padding: 8px 14px;
  border-radius: 4px;
  cursor: pointer;
}
.side-menu li:hover {
  background: #f0f0f0;
}
.side-menu .active {
  background: #e2e2e2;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
}

/* Main Content */
.community-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
}

/* Topic Bar (the pill buttons) */
.topic-bar {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  flex-wrap: wrap;
  /* Center them horizontally */
  justify-content: center; 
}
.topic-btn {
  background: #f5f5f5;
  border: none;
  padding: 6px 12px;
  border-radius: 16px;
  cursor: pointer;
  font-size: 14px;
  font-family: "Quicksand", sans-serif;
}
.topic-btn.selected {
  background: #007291;
  color: #fff;
}

/* New post form */
.new-post-form {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 20px;
}
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.post-input-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.post-input-container textarea {
  width: 100%;
  min-height: 60px;
  resize: vertical;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 6px;
  /* font-family: inherit; */
  font-family: "Quicksand", sans-serif;
}
.post-form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}
.post-form-actions button {
  background: #007291;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  cursor: pointer;
}

/* Feed Container */
.feed-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Post Card */
.post-card {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.post-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.post-user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.post-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.author-time h5 {
  margin: 0;
  font-size: 16px;
  font-family: "Quicksand", sans-serif;
}
.author-time span {
  font-size: 12px;
  color: #999;
  font-family: "Quicksand", sans-serif;
}
.post-dots {
  position: relative;
  display: inline-block;
}

/* The 3-dot menu styling */
.dots-menu {
  position: absolute;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 80px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.dots-menu button {
  background: none;
  border: none;
  text-align: left;
  width: 100%;
  padding: 4px 8px;
  cursor: pointer;
  color: #555;
}
.dots-menu button:hover {
  background: #f0f0f0;
}

/* Post Content */
.post-content p {
  margin: 0;
  line-height: 1.5;
}
.edit-mode textarea {
  width: 100%;
  min-height: 60px;
  resize: vertical;
  margin-bottom: 8px;
}
.edit-buttons {
  display: flex;
  gap: 8px;
}

/* Post Footer (like/comment/share) */
.post-footer {
  display: flex;
  gap: 20px;
}
.post-footer button {
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  cursor: pointer;
  color: #007291;
  font-size: 14px;
  font-family: "Quicksand", sans-serif;
}

/* Comment input row */
.comment-input-wrapper {
  display: flex;
  gap: 8px;
  margin-top: 6px;
}
.comment-input-wrapper input {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 6px;
  /* font-family: inherit; */
  font-family: "Quicksand", sans-serif;
}
.comment-input-wrapper button {
  background: #007291;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  cursor: pointer;
}

/* Comment List */
.comment-list {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-top: 1px solid #eee;
  padding-top: 12px;
}
.comment-item {
  display: flex;
  gap: 10px;
}
.comment-author-avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.comment-details h6 {
  margin: 0;
  font-size: 14px;
  color: #333;
  font-family: "Quicksand", sans-serif;
}
.comment-details h6 span {
  font-size: 12px;
  color: #999;
  margin-left: 6px;
  font-family: "Quicksand", sans-serif;
}
.comment-details p {
  margin: 4px 0;
  font-size: 14px;
  line-height: 1.4;
  font-family: "Quicksand", sans-serif;
}
.comment-footer {
  display: flex;
  gap: 8px;
}
.comment-footer button {
  background: none;
  border: none;
  cursor: pointer;
  color: #007291;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: "Quicksand", sans-serif;
}
/* In Community.css */
.community-heading {
  margin: 0 0 16px 14px; /* same left offset as the side menu items */
  font-size: 20px;
  font-family: "Quicksand", sans-serif;
  color: #333;
}


