/* /* index.css */

/* 1) Import Quicksand from Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap");

html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  /* 2) Now use Quicksand as your primary font */
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code blocks can stay as is, if you like */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

