/* TermsAndConditions.css */

.terms-page {
  background-color: #fdfeff;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  font-family: "Quicksand", sans-serif;
  color: #333;
}

.terms-container {
  width: 90%;
  max-width: 800px;
  font-family: "Quicksand", sans-serif;
}

.terms-container h1 {
  font-size: 48px;
  text-align: center;
  margin-bottom: 30px;
  font-family: "Quicksand", sans-serif;
}

.collapsible-section {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  transition: background-color 0.2s ease;
  font-family: "Quicksand", sans-serif;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
}

.section-header h2 {
  font-size: 24px;
  margin: 0;
  font-family: "Quicksand", sans-serif;
}

.toggle-icon {
  font-size: 24px;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
}

.section-content {
  margin-top: 10px;
  font-size: 18px;
  line-height: 1.6;
  font-family: "Quicksand", sans-serif;
}

.section-content p {
  margin: 0;
  font-family: "Quicksand", sans-serif;
}

/* New Footer Styling for Terms and Conditions */
.terms-footer {
  text-align: center;
  font-size: 16px;
  opacity: 0.7;
  padding-top: 20px;
  border-top: 1px solid #ddd;
  margin-top: 20px;
  font-family: "Quicksand", sans-serif;
}

.terms-footer p {
  margin: 8px 0;
}

.terms-footer a {
  text-decoration: none;
  color: #007291;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
}
