/* Single card layout */
.single-card-layout {
  max-width: 600px;
  margin: 0 auto; /* center the content */
  text-align: left;
}

/* The hero image */
.car-hero-image img {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: "Quicksand", sans-serif;
}

/* The card below the image */
.single-reco-card {
  background: #fff;
  border: 1px solid #e5e7eb;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 1.5rem;
  position: relative;
}

/* Title + header buttons row */
.card-header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Container for header buttons (favorite and compare) */
.card-header-buttons {
  display: flex;
  align-items: center;
}

/* The star favorite button */
.star-favorite-button {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #888;
  transition: color 0.2s;
  font-family: "Quicksand", sans-serif;
}
.star-favorite-button:hover {
  color: #f59e0b;
}

/* Compare button */
.compare-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-left: 8px;
  font-family: "Quicksand", sans-serif;
}
.compare-icon {
  width: 48px;
  height: 48px;
}

/* Lookfor list */
.lookfor-list {
  list-style: disc;
  margin: 1rem 0;
  padding-left: 1.5rem;
  font-family: "Quicksand", sans-serif;
}
.lookfor-list li {
  font-family: "Quicksand", sans-serif;
}

/* Bottom buttons row */
.bottom-buttons-row {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.see-listings-button, 
.next-button {
  background-color: #dae1ef;
  color: #fff;
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
  cursor: pointer;
}
.see-listings-button:hover, 
.next-button:hover {
  background-color: #dfe4f0; 
}
.no-more-cars {
  color: #666;
  font-size: 0.9rem;
  font-family: "Quicksand", sans-serif;
}
.listings-section {
  margin: 1rem 0;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 1rem;
  background: #f9fafb;
  font-family: "Quicksand", sans-serif;
}
.listing-item {
  margin-bottom: 1rem;
  font-family: "Quicksand", sans-serif;
}

/* Start Over button */
.start-over-button {
  margin-top: 2rem;
  background-color: #4b5563;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin-left: auto; 
  margin-right: auto; 
  font-family: "Quicksand", sans-serif;
}
.start-over-button:hover {
  background-color: #374151;
}

/* Plain text button for "Next" */
.next-button {
  background: none;
  border: none;
  color: #333;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
  padding: 4px 8px;
  outline: none;
  font-family: "Quicksand", sans-serif;
}
.next-button:hover {
  text-decoration: underline;
}

/* Pill-shaped, teal button for "See Listings" */
.see-listings-button {
  background-color: #007291;
  border: none;
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;
  font-family: "Quicksand", sans-serif;
}
.see-listings-button:hover {
  background-color: #005b73;
}

/* Additional text styling */
.car-description,
.card-title,
.no-listings,
.recommendations-container,
.section-heading,
.spec-label {
  font-family: "Quicksand", sans-serif;
}

/* Compare Modal Overlay */
.compare-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Compare Modal Box */
.compare-modal {
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  font-family: "Quicksand", sans-serif;
}

/* Compare Modal Header */
.compare-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

/* Compare Table Styles */
.compare-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}
.compare-table th,
.compare-table td {
  border: 1px solid #ccc;
  padding: 0.5rem;
  text-align: left;
  font-family: "Quicksand", sans-serif;
}

/* Compare Selection Styles */
.compare-selection-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.compare-select-item {
  display: flex;
  align-items: center;
}

.compare-select-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.compare-select-image {
  width: 100px;
  height: auto;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Styling for compare images above the table */
.compare-images {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.compare-image-container {
  text-align: center;
}

.compare-car-image {
  width: 200px;
  height: auto;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}
