/* Global scale wrapper to shrink content by 20% */
.scale-wrapper {
  transform: scale(0.8);
  transform-origin: top center;
  width: 100%;
  margin: 0 auto;
}

/* Basic container styling for Navbar */
.header {
  width: 100%;
  background-color: #fff;
  border-bottom: 0.8px solid #3c414280;
  font-family: "Quicksand", sans-serif;
}

/* The top navbar region */
.nav-top {
  width: 100%;
  height: 98px;
}

/* Navbar row containing logo, links, etc. */
.navbar-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
  height: 100%;
  padding: 0 20px;
}

/* Brand container: Logo and text */
.brand-container {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  font-family: "Quicksand", sans-serif;
}

/* Logo styling */
.qarby-primary-logo-2 {
  display: block;
  height: 50px;
  width: auto;
  font-family: "Quicksand", sans-serif;
}

/* Brand text styling */
.brand-text {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  font-family: "Quicksand", sans-serif;
}

/* Middle links container */
.frame-11 {
  display: flex;
  align-items: center;
  gap: 40px;
  font-family: "Quicksand", sans-serif;
}

/* Middle links text */
.text-wrapper-30 {
  color: #333;
  font-size: 24px;
  font-weight: 300;
  text-decoration: none;
  font-family: "Quicksand", sans-serif;
}

.text-wrapper-30:hover {
  color: #007291;
  font-family: "Quicksand", sans-serif;
}

/* Right side container */
.frame-12 {
  display: flex;
  align-items: center;
  gap: 24px;
  font-family: "Quicksand", sans-serif;
}

/* Welcome message styling */
.welcome-message {
  color: #2e2e2e;
  font-size: 16px;
  font-family: "Quicksand", sans-serif;
}

/* Login button styling */
.login-button {
  background-color: #007291;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.3s, transform 0.3s;
  font-family: "Quicksand", sans-serif;
}

.login-button:hover {
  background-color: #005f70;
  transform: scale(1.02);
  font-family: "Quicksand", sans-serif;
}

/* Hamburger for mobile */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 4px;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease;
}

/* Mobile dropdown menu */
.navbar-links-container {
  display: none;
  position: absolute;
  top: 98px;
  left: 0;
  right: 0;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  z-index: 9999;
}

.navbar-links-container.open {
  display: block;
}

.navbar-links {
  list-style: none;
  padding: 20px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.navbar-links li a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-family: "Quicksand", sans-serif;
}

.navbar-links li a:hover {
  color: #007291;
}

/* RESPONSIVE: Hide middle links and adjust mobile view */
@media (max-width: 768px) {
  .frame-11 {
    display: none;
  }
  .text-wrapper-31 {
    display: none;
  }
  .hamburger {
    display: flex;
  }
}
