/* Make the carousel + text look like one card */
.car-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden; /* so the top image corners round together */
    margin-bottom: 1.5rem;
    background: #fff;
  }
  
  .car-info-section {
    padding: 1.5rem;
  }
  
  .carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .carousel-slide img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    display: block;
  }
  
  /* Hide arrow buttons if using purely swipe or 'hideArrows' is true:
     You can do display: none or rely on the prop logic in the code.
  */
  .carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0,0,0,0.4);
    border: none;
    color: #fff;
    font-size: 2rem;
    cursor: pointer;
    padding: 0.3rem 0.6rem;
    z-index: 2;
  }
  .carousel-arrow.left {
    left: 0.5rem;
  }
  .carousel-arrow.right {
    right: 0.5rem;
  }
  
  /* Dot indicators */
  .carousel-indicators {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 6px;
  }
  .carousel-indicators .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ccc;
    cursor: pointer;
  }
  .carousel-indicators .dot.active {
    background: #007291; 
  }
  