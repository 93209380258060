/* 
  The .wrapper is fluid and centers itself.
  It uses a max-width of 1416px with a padding‑bottom trick to preserve a 1416×600 aspect ratio
  (600/1416 ≈ 42.4%) and applies a 20px border‑radius for curvy corners.
*/
.wrapper {
  position: relative;
  width: 100%;
  max-width: 1416px;
  margin: 25px auto 0 auto; /* 25px top margin */
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  padding-bottom: 42.4%;
  
  /* Scale down by 15% (i.e. 85% of original size) */
  transform: scale(0.85);
  transform-origin: top center;
}

/* Video container fills the wrapper */
.videoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

/* White overlay container for CTA elements—
   always positioned absolutely relative to .wrapper, anchored at bottom-right.
*/
.whiteOverlay {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: #fff;
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  z-index: 10;
  width: auto;        /* Wrap content */
  max-width: 80%;
}

/* Inner overlay content arranged in two columns (left and right) */
.overlayContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

/* Left and right content containers */
.leftContent,
.rightContent {
  display: flex;
  align-items: center;
}

/* "Find your Car" button container with arrow */
.findCarButtonContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #007291;
  border-radius: 10px;
  padding: 8px 15px;
  font-family: "Quicksand", sans-serif;
}

.findCarButtonText {
  text-decoration: none;
  color: #fff;
  font-family: "Quicksand", sans-serif;
  /* Using clamp for fluid scaling between 16px and 20px */
  font-size: clamp(16px, 2.5vw, 20px);
  font-weight: 700;
}

.findCarArrowImg {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

/* Matchmaker icons list */
.matchmakerIconsList {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.matchmakerIconBg {
  width: 36px;
  height: 36px;
  background-color: #424242;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.matchmakerIcon {
  width: 18px;
  height: 18px;
  fill: #fff;
}

.matchmakerIconImg {
  width: 18px;
  height: 18px;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .wrapper {
    max-width: 100% !important;
    margin: 25px auto;
    padding-bottom: 42.4%;
    border-radius: 10px;
    
    /* Optionally adjust or remove scaling on mobile if needed */
    transform: scale(0.85);
    transform-origin: top center;
  }
  /* Keep whiteOverlay absolutely positioned relative to .wrapper */
  .whiteOverlay {
    bottom: 10px;
    right: 10px;
    padding: 8px 10px;
    border-radius: 5px;
    max-width: 90%;
  }
  .overlayContent {
    /* Optionally adjust layout if needed; here we keep the same two-column layout */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  .findCarButtonContainer {
    padding: 8px 10px;
  }
  .findCarButtonText {
    font-size: clamp(14px, 3vw, 16px);
    font-family: "Quicksand", sans-serif;
  }
  .findCarArrowImg {
    width: 16px;
    height: 16px;
  }
  .matchmakerIcon {
    width: 16px;
    height: 16px;
  }
  .matchmakerIconImg {
    width: 16px;
    height: 16px;
  }
}
