/************************************************************
 * ORIGINAL CSS (commented out but kept for reference)
 ************************************************************/

/* .entrance-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    padding: 80px 0;
    margin: 0 auto;
}

.card {
    background-color: #fff;
    border-radius: 12px;
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    color: inherit;
    box-shadow:
        6px 6px 0px 0px black,
        12px 12px 0px 0px black,
        18px 18px 0px 0px black;
    transition: box-shadow 0.3s ease;
}

.card:hover {
    text-decoration: none;
    color: inherit;
    box-shadow:
        6px 6px 0px 0px black,
        12px 12px 0px 0px black,
        18px 18px 0px 0px black;
}

.card h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    font-weight: 600;
    color: #000;
}

.card p {
    font-size: 0.9rem;
    color: #333;
    max-width: 220px;
    line-height: 1.4;
}

@media (max-width: 992px) {
    .entrance-cards {
        gap: 40px;
        padding: 40px 0;
    }
    .card {
        width: 280px;
        height: 280px;
    }
}

@media (max-width: 768px) {
    .entrance-cards {
        flex-direction: column;
        align-items: center;
        gap: 30px;
        padding: 20px 0;
    }
    .card {
        width: 250px;
        height: 250px;
        box-shadow:
            4px 4px 0px 0px black,
            8px 8px 0px 0px black,
            12px 12px 0px 0px black;
    }
    .sidebar {
        width: 200px;
    }
}

@media (max-width: 480px) {
    .sidebar {
        display: none;
    }
    .main-content {
        padding: 0 10px;
    }
    .card {
        width: 90%;
        height: auto;
        min-height: 200px;
        box-shadow:
            3px 3px 0px 0px black,
            6px 6px 0px 0px black,
            9px 9px 0px 0px black;
    }
    .card h2 {
        font-size: 1.4rem;
    }
    .card p {
        font-size: 0.85rem;
    }
} */

/************************************************************
 * ANIMA-GENERATED CSS
 ************************************************************/
.macbook-pro {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

/* The main container with absolute positioning (for demonstration) */
.macbook-pro .div {
    background-color: #ffffff;
    height: 1225px;
    /* or auto if you want to remove the large empty space */
    position: relative;
    width: 1512px;
}

/* The top-right user icon/notification section */
.macbook-pro .overlap {
    position: absolute;
    top: 23px;
    left: 1250px;
    width: 235px;
    height: 50px;
}

.macbook-pro .rectangle {
    background-color: #d9d9d9;
    border-radius: 11px;
    position: absolute;
    top: 9px;
    left: 0;
    width: 204px;
    height: 36px;
}

.macbook-pro .ellipse {
    background-color: #ff3b00;
    border-radius: 25px;
    position: absolute;
    top: 0;
    left: 185px;
    width: 50px;
    height: 50px;
}

.macbook-pro .text-wrapper {
    color: #000000;
    font-family: "Helvetica-Bold", Helvetica;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    position: absolute;
    top: 9px;
    left: 201px;
    white-space: nowrap;
}

/* Example left-hand rectangle (like a small black bar) */
.macbook-pro .rectangle-2 {
    background-color: #000000;
    border-radius: 11px;
    position: absolute;
    top: 37px;
    left: 33px;
    width: 204px;
    height: 36px;
}

/* ======================
     ASK QARBY CARD (LEFT)
     ====================== */
.macbook-pro .overlap-group {
    position: absolute;
    top: 341px;
    left: 210px;
    width: 536px;
    height: 473px;
}

.macbook-pro .CARD-FOR-ASK-QARBY {
    position: absolute;
    top: 0;
    left: 0;
    width: 536px;
    height: 473px;
}

.macbook-pro .overlap-group-2 {
    position: relative;
    width: 537px;
    height: 474px;
    /* 1px bigger than the container */
}

/* Left card layers (matching the right card’s offsets/borders) */
.macbook-pro .rectangle-3 {
    background: linear-gradient(180deg,
            rgb(146, 140, 140) 0%,
            rgb(136, 136, 136) 2%,
            rgb(44, 42, 42) 100%);
    border: 1px solid #ffffff;
    border-radius: 30px;
    position: absolute;
    top: 53px;
    left: 54px;
    width: 483px;
    height: 421px;
}

.macbook-pro .rectangle-4 {
    background-color: #090909;
    border: 1px solid #fafafa;
    border-radius: 30px;
    position: absolute;
    top: 31px;
    left: 27px;
    width: 481px;
    height: 419px;
}

.macbook-pro .rectangle-5 {
    background-color: #ffffff;
    border: 1px solid #918b8b;
    border-radius: 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 480px;
    height: 428px;
}

/* Ask Qarby heading */
.macbook-pro .text-wrapper-2 {
    color: #000000;
    font-family: "Helvetica-Regular", Helvetica;
    font-size: 48px;
    text-align: center;
    position: absolute;
    top: 272px;
    left: 37px;
    white-space: nowrap;
    width: 231px;
    text-decoration: none;
    /* so Link text won't appear underlined by default */
}

.macbook-pro .text-wrapper-2:hover {
    text-decoration: underline;
}

/* Subtext under "Ask Qarby" */
.macbook-pro .let-s-chat-about {
    color: #807d7d;
    font-family: "Helvetica-Light", Helvetica;
    font-size: 16px;
    font-weight: 300;
    position: absolute;
    top: 340px;
    left: 40px;

    /* Underline on hover, same as the heading */
    text-decoration: none;
}

.macbook-pro .let-s-chat-about:hover {
    text-decoration: underline;
}

/* ===========================
     FIND YOUR CAR CARD (RIGHT)
     =========================== */
.macbook-pro .overlap-2 {
    position: absolute;
    top: 341px;
    left: 816px;
    width: 536px;
    height: 473px;
}

.macbook-pro .overlap-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 536px;
    height: 473px;
}

.macbook-pro .overlap-3 {
    position: relative;
    width: 537px;
    height: 474px;
}

/* Right card layers */
.macbook-pro .rectangle-6 {
    background: linear-gradient(180deg,
            rgb(146, 140, 140) 0%,
            rgb(136, 136, 136) 2%,
            rgb(44, 42, 42) 100%);
    border: 1px solid #ffffff;
    border-radius: 30px;
    position: absolute;
    top: 53px;
    left: 54px;
    width: 483px;
    height: 421px;
}

.macbook-pro .rectangle-7 {
    background-color: #090909;
    border: 1px solid #fafafa;
    border-radius: 30px;
    position: absolute;
    top: 31px;
    left: 27px;
    width: 481px;
    height: 419px;
}

.macbook-pro .rectangle-8 {
    background-color: #ffffff;
    border: 1px solid #918b8b;
    border-radius: 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 480px;
    height: 428px;
}

/* "Find Your Car" heading */
.macbook-pro .text-wrapper-3 {
    color: #000000;
    font-family: "Helvetica-Regular", Helvetica;
    font-size: 48px;
    text-align: center;
    position: absolute;
    top: 272px;
    left: 22px;
    white-space: nowrap;
    width: 290px;
    text-decoration: none;
}

.macbook-pro .text-wrapper-3:hover {
    text-decoration: underline;
}

/* Subtext under "Find Your Car" */
.macbook-pro .we-d-help-you-find-a {
    color: #807d7d;
    font-family: "Helvetica-Light", Helvetica;
    font-size: 16px;
    font-weight: 300;
    position: absolute;
    top: 340px;
    left: 29px;

    /* Underline on hover, same as the heading */
    text-decoration: none;
}

.macbook-pro .we-d-help-you-find-a:hover {
    text-decoration: underline;
}


/* =========================
     MEDIA QUERIES FOR RESPONSIVENESS
     ========================= */

/* Tablet-ish: under 992px wide */
/* Put this entire block at the VERY END of your AskOrBuy.css (or whichever file)
   so it overrides everything above. */
   @media (max-width: 992px) {
    /* Let the big container auto-size instead of fixed 1512px,
       so it no longer overflows horizontally */
    .macbook-pro .div {
      width: auto !important;
      height: auto !important;
      position: static !important;
      margin: 0 auto !important;
      padding: 20px !important; /* optional breathing room */
    }
  
    /* Switch the main `.macbook-pro` flex to column so cards stack */
    .macbook-pro {
      flex-direction: column !important;
      align-items: center !important;
      justify-content: flex-start !important;
    }
  
    /* -------------------------------
       LEFT CARD (Ask Qarby)
       ------------------------------- */
  
    /* Overlap container becomes relative & shrinks */
    .macbook-pro .overlap-group {
      position: relative !important;
      top: auto !important;
      left: auto !important;
      margin: 0 auto 40px auto !important;
      /* This is the new total card size for mobile */
      width: 300px !important;
      height: 300px !important;
    }
  
    /* Each rectangle is still absolutely positioned 
       so it can layer, but inside the smaller container. */
    .macbook-pro .rectangle-3,
    .macbook-pro .rectangle-4,
    .macbook-pro .rectangle-5 {
      width: 270px !important;  /* smaller than 300px container */
      height: 220px !important;
    }
  
    /* Now pick smaller offsets so they layer 
       but don’t overflow horizontally */
    .macbook-pro .rectangle-3 {
      top: 40px !important;
      left: 40px !important;
    }
    .macbook-pro .rectangle-4 {
      top: 20px !important;
      left: 20px !important;
    }
    .macbook-pro .rectangle-5 {
      top: 0 !important;
      left: 0 !important;
    }
  
    /* Same idea for the text.  You can either keep them absolute 
       or just switch to static positioning and center them. 
       Below is an example of still using absolute but smaller offsets. */
    .macbook-pro .text-wrapper-2 {
      position: absolute !important;
      top: 100px !important;
      left: 30px !important;
      font-size: 24px !important;
      width: 200px !important; /* keep text from overflowing */
      text-align: center !important;
    }
    .macbook-pro .let-s-chat-about {
      position: absolute !important;
      top: 140px !important;
      left: 30px !important;
      font-size: 14px !important;
      color: #807d7d !important;
      width: 220px !important; 
      text-align: center !important;
    }
  
    /* -------------------------------
       RIGHT CARD (Find Your Car)
       ------------------------------- */
    
    /* Same changes for the right card’s overlap container */
    .macbook-pro .overlap-2 {
      position: relative !important;
      top: auto !important;
      left: auto !important;
      margin: 0 auto 40px auto !important;
      width: 300px !important;
      height: 300px !important;
    }
  
    /* The “layers” for the right card */
    .macbook-pro .rectangle-6,
    .macbook-pro .rectangle-7,
    .macbook-pro .rectangle-8 {
      width: 270px !important;
      height: 220px !important;
    }
    .macbook-pro .rectangle-6 {
      top: 40px !important;
      left: 40px !important;
    }
    .macbook-pro .rectangle-7 {
      top: 20px !important;
      left: 20px !important;
    }
    .macbook-pro .rectangle-8 {
      top: 0 !important;
      left: 0 !important;
    }
  
    /* And the text for the right card */
    .macbook-pro .text-wrapper-3 {
      position: absolute !important;
      top: 100px !important;
      left: 30px !important;
      font-size: 24px !important;
      width: 200px !important;
      text-align: center !important;
    }
    .macbook-pro .we-d-help-you-find-a {
      position: absolute !important;
      top: 140px !important;
      left: 30px !important;
      font-size: 14px !important;
      color: #807d7d !important;
      width: 220px !important;
      text-align: center !important;
    }
  }
  