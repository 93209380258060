/* File: src/pages/DealerPortal.css */

.portal-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .dealer-form, .add-vehicle-form {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .dealer-form label, 
  .add-vehicle-form label {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    margin-bottom: 8px;
  }
  
  .add-vehicle-form input[type="text"],
  .add-vehicle-form input[type="number"] {
    padding: 6px 8px;
    margin-top: 4px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .btn-add,
  .btn-delete {
    margin-top: 8px;
    padding: 8px 16px;
    background-color: #4caf50; /* green */
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
  }
  
  .btn-delete {
    background-color: #f44336; /* red */
    margin-bottom: 10px;
  }
  
  .btn-add:hover,
  .btn-delete:hover {
    opacity: 0.9;
  }
  
  .inventory-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 16px;
  }
  
  .inventory-table th,
  .inventory-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .inventory-table th {
    background-color: #f2f2f2;
    font-weight: 600;
  }
  
  .inventory-table tr:nth-child(even) {
    background-color: #fafafa;
  }
  