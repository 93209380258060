/* Global reset to prevent horizontal scrolling */
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* ── Desktop (Original) Styles ── */

/* MEET QARBY BACKGROUND */
.meet-qarby-background {
  background-image: url("https://cdn.animaapp.com/projects/678a985e0cccaf0892a06cc5/releases/678a99f1cefd91045472e2fe/img/mask-group.png");
  background-size: 100% 100%;
  width: 1132px;
  height: 728px;
  /* Center the container in the document flow */
  margin: 2rem auto;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

/* MEET QARBY CONTENT */
.meet-qarby-content {
  /* Desktop: use absolute positioning (using CommunityCTA’s dimensions) */
  position: absolute;
  width: 627px;
  height: 376px;
  top: 176px;
  left: 254px;
  text-align: center;
  box-sizing: border-box;
}

/* MEET QARBY TITLE */
.meet-qarby-title {
  color: var(--text);
  font-family: "Quicksand", sans-serif;
  font-size: 56px;
  font-weight: 700;
  line-height: 68.9px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 50%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Adjust for the title’s own width */
  white-space: nowrap;
}


/* MEET QARBY SUBTITLE */
.meet-qarby-subtitle {
  color: var(--text);
  font-family: "Quicksand", sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 93px;
  left: 0;
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
  padding: 0 40px;
  box-sizing: border-box;
  overflow-wrap: break-word;
}

/* ── Responsive Adjustments ── */

/* For viewports 768px and below */
@media (max-width: 768px) {

  /* Scale down the overall container while preserving boundaries */
  .meet-qarby-background {
    width: 90%;
    /* Allow the height to adjust automatically */
    height: auto;
    background: url("https://cdn.animaapp.com/projects/678a985e0cccaf0892a06cc5/releases/678a99f1cefd91045472e2fe/img/mask-group.png") no-repeat center;
    background-size: contain;
    margin: 1rem auto;
    padding: 1rem;
    /* Provides a neat boundary */
    box-sizing: border-box;
  }

  /* Let inner content flow naturally by removing absolute positioning */
  .meet-qarby-content {
    position: relative;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }


  @media (max-width: 768px) {
    .meet-qarby-title {
      position: static;
      transform: none; /* Remove the desktop transform */
      font-size: clamp(32px, 6vw, 48px);
      margin-bottom: 0.5rem;
      white-space: normal;
      text-align: center;
    }
  }
  


  .meet-qarby-subtitle {
    position: static;
    font-size: clamp(16px, 4vw, 22px);
    padding: 0 10px;
    margin-bottom: 1rem;
  }
}

/* For viewports 480px and below */
@media (max-width: 480px) {
  .meet-qarby-title {
    position: static;
    font-size: clamp(28px, 6vw, 42px);
    margin-bottom: 0.5rem;
    text-align: center;
    /* Again, let the title’s natural width determine its size */
  }

  .meet-qarby-subtitle {
    font-size: clamp(14px, 4vw, 20px);
    padding: 0 8px;
  }
}