.results-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

.car-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.car-card {
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.car-card h2 {
    font-size: 20px;
    margin-bottom: 10px;
}

.car-card p {
    font-size: 16px;
}

.car-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}
.car-card:hover {
    transform: scale(1.05);
}
.car-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}
.car-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
}
