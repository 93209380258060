.dealership-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.dealership-header {
    margin-bottom: 2rem;
    text-align: center;
}

.dealership-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-top: 2rem;
}

.dealership-card {
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 1.5rem;
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
}

.dealership-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dealership-name {
    font-size: 1.25rem;
    font-weight: bold;
    color: #111827;
    margin-bottom: 0.5rem;
}

.dealership-address {
    color: #6b7280;
    font-size: 0.875rem;
    margin-bottom: 1rem;
}

.dealership-rating {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 0.5rem;
}

.rating-star {
    color: #fbbf24;
}

.dealership-status {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    display: inline-block;
    margin-top: 0.5rem;
}

.status-open {
    background-color: #d1fae5;
    color: #065f46;
}

.status-closed {
    background-color: #fee2e2;
    color: #991b1b;
}

.error-message {
    background-color: #fee2e2;
    border: 1px solid #f87171;
    color: #991b1b;
    padding: 1rem;
    border-radius: 6px;
    margin-bottom: 1rem;
}

.loading-spinner {
    text-align: center;
    padding: 2rem;
    color: #6b7280;
}

@media (max-width: 768px) {
    .dealership-container {
        padding: 1rem;
    }
    
    .dealership-grid {
        grid-template-columns: 1fr;
    }
}