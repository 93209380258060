/* AskQarbyPage.css */

/* Main page container */
.ask-qarby-page {
  background: #fff;
  /* Take up the remaining height below the navbar */
  min-height: calc(100vh - 98px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* Added bottom padding to allow for fixed elements on mobile (if any) */
  padding: 0 1rem 150px;
  box-sizing: border-box;
}

/* Chat content area */
.ask-qarby-content {
  width: 100%;
  max-width: 800px; 
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

/* Disclaimer text */
.ask-qarby-disclaimer {
  margin-top: 40px;
  text-align: center;
  font-size: 14px;
  color: #666;
  max-width: 600px;
  line-height: 1.4;
  font-family: "Quicksand", sans-serif;
}

.ask-qarby-disclaimer a {
  color: #007291;
  text-decoration: none;
}

/* Bottom icons container */
/* By default, hide the bottom icons (for small screens where BottomNav is present) */
.ask-qarby-bottom-icons {
  display: none;
}

/* On larger screens, show the bottom icons as part of the page flow underneath the disclaimer */
@media (min-width: 769px) {
  .ask-qarby-bottom-icons {
    display: flex;
    position: static;
    margin-top: 20px;
    gap: 30px;
    justify-content: center;
  }
}

/* Global icon classes (same as used in BottomNav) */
.nav-item {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-icon-bg {
  background-color: #000;  /* Default black */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.nav-item:active .nav-icon-bg,
.nav-item:focus .nav-icon-bg,
.nav-item:hover .nav-icon-bg {
  background-color: #007291;
}

.nav-icon {
  width: 18px;
  height: 18px;
  object-fit: contain;
  color: #fff;
}

/* ----- Chat Conversation and Input Styles ----- */

.askqarby-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
}

.askqarby-conversation {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 300px; /* or adjust as needed */
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.user-message {
  align-self: flex-end;
  background-color: #007291;
  color: #fff;
  padding: 10px 14px;
  border-radius: 20px;
  border-bottom-right-radius: 5px;
  max-width: 80%;
  margin: 4px 0;
}

.assistant-message {
  align-self: flex-start;
  background-color: #f1f1f1;
  color: #333;
  padding: 10px 14px;
  border-radius: 20px;
  border-bottom-left-radius: 5px;
  max-width: 80%;
  margin: 4px 0;
}

.askqarby-input-container {
  display: flex;
  align-items: center;
  background-color: #f5f7f9;
  border: 1px solid #ccd2d9;
  border-radius: 12px;
  padding: 0 16px;
  height: 60px;
}

.askqarby-input-container input {
  flex: 1;
  border: none;
  background: transparent;
  font-size: 16px;
  color: #333;
  outline: none;
}

.askqarby-input-container input::placeholder {
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  color: #999;
}

/* For cross-browser support */
.askqarby-input-container input::-webkit-input-placeholder {
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  color: #999;
}

.askqarby-input-container input:-ms-input-placeholder {
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  color: #999;
}

.askqarby-input-container button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
  margin-left: 8px;
}

.askqarby-input-container button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.user-message,
.assistant-message {
  font-family: "Quicksand", sans-serif;
}

/* Existing styles for .askqarby-input-container (from your CSS) */
.askqarby-input-container {
  display: flex;
  align-items: center;
  background-color: #f5f7f9;
  border: 1px solid #ccd2d9;
  border-radius: 12px;
  padding: 0 16px;
  height: 60px;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

/* Pre-chat state: glowing effect */
.askqarby-input-container.glow {
  border: 1px solid #007291;
  animation: glow 1.5s infinite;
}

/* Post-chat state: blue border without glow */
.askqarby-input-container.blue {
  border: 1px solid #007291;
  box-shadow: none;
  animation: none;
}

/* Define the glowing animation */
@keyframes glow {
  0% {
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.7);
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 123, 255, 1);
  }
  100% {
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.7);
  }
}

/* Larger screens: Increase input container size and font size */
@media (min-width: 769px) {
  .askqarby-input-container {
    height: 70px;
    padding: 0 24px;
  }
  .askqarby-input-container input {
    font-size: 18px;
  }
}
