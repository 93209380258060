/* Garage.css */

.garage-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  font-family: "Quicksand", sans-serif;
  padding: 20px 20px 150px 20px; /* Extra bottom padding for footer */
  box-sizing: border-box;
}

/* --- Car Hero Section (Horizontal Layout with Centered Elements) --- */
.car-hero-container {
  width: 100%;
  display: flex;
  align-items: center; /* Center vertically */
  margin-bottom: 30px;
  position: relative;
}

/* Left: Car image container */
.car-hero-image {
  flex: 0 0 150px;  /* Fixed width */
  height: 150px;
  margin-right: 20px;
}

.hero-car-img {
  width: 150px;
  height: 150px;
  display: block;
  border-radius: 50%; /* Circular image */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  object-fit: cover;
}

/* Right: Details box */
.car-hero-details {
  flex: 1;
  background-color: #027180; 
  color: #fff;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  text-transform: uppercase;
  text-align: center; /* Center text */
}

.car-hero-details h2 {
  margin: 0 0 8px;
  font-size: 20px; /* Reduced */
  line-height: 1.2;
  font-family: "Quicksand", sans-serif;
}

.car-hero-details p {
  margin: 4px 0;
  font-size: 14px; /* Reduced */
  font-family: "Quicksand", sans-serif;
}

/* Updated Edit Car Button: Blue background with white text */
.edit-car-btn {
  margin-top: 10px;
  background-color: #007291; /* Blue color */
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  text-transform: none;
  font-family: "Quicksand", sans-serif;
}

/* --- Car Form Modal --- */
.car-form-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.car-form-modal-content {
  background: #fff;
  width: 90%;
  max-width: 500px;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  box-shadow: 0 2px 8px rgba(0,0,0,0.25);
  font-family: "Quicksand", sans-serif;
}

.car-form-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
}

/* New Row Layout for Car Form Fields */
.car-form-row {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.car-form-row label {
  flex: 0 0 140px;
  font-size: 14px;
}

.car-form-row .input-container {
  flex: 1;
}

.car-form-row input {
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Button row for form actions */
.car-form-btn-row {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 12px;
}

/* --- Favorites Section (Updated On-Brand) --- */
.favorites-section {
  position: relative;
  margin-bottom: 60px;
  font-family: "Quicksand", sans-serif;
}

/* Background rectangle: offset for layered effect */
.favorites-background-rect {
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  min-height: 300px;
  background: #f0f7fa; /* Light blue tint */
  border: 2px solid #007291; /* Ocean blue border */
  border-radius: 16px;
  z-index: 1;
}

/* Content rectangle on top */
.favorites-content-rect {
  position: relative;
  z-index: 2;
  margin: 0;
  background: #fff;
  border: 2px solid #007291;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 20px;
}

/* Favorites Title Row */
.favorites-title-row {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #007291;
}

.favorites-title-row h2 {
  margin: 0;
  font-size: 26px;
  text-transform: uppercase;
  font-family: "Quicksand", sans-serif;
}

.favorites-star {
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
}

/* Favorites scroll area */
.favorites-scroll-area {
  max-height: 280px;
  overflow-y: auto;
  padding-right: 8px;
}

.no-favorites-msg {
  margin: 0;
  color: #777;
  font-size: 14px;
  font-family: "Quicksand", sans-serif;
}

.favorite-entry {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px;
  margin-bottom: 16px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  background-color: #fafafa;
}

.favorite-entry:last-child {
  margin-bottom: 0;
}

.fav-img-box {
  width: 120px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 16px;
  flex-shrink: 0;
}

.fav-img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fav-no-image {
  width: 100%;
  height: 100%;
  border: 1px dashed #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
}

.fav-text {
  flex: 1;
  margin-right: 80px;
}

.fav-text h4 {
  margin: 0 0 8px;
  font-size: 18px;
  text-transform: uppercase;
  font-family: "Quicksand", sans-serif;
}

.fav-text p {
  margin: 0;
  font-size: 14px;
  color: #555;
  line-height: 1.4;
  font-family: "Quicksand", sans-serif;
}

.fav-purchase-button {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #007291;
  color: #fff;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-family: "Quicksand", sans-serif;
}

.fav-purchase-button:hover {
  background-color: #006070;
}

/* --- Garage Bottom Icons --- */
.garage-bottom-icons {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-bottom: 20px;
}

.icon-circle {
  width: 50px;
  height: 50px;
  background-color: #3c4142;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* --- Garage Footer --- */
.garage-footer {
  text-align: center;
  font-size: 14px;
  color: #666;
  margin-top: 20px;
  font-family: "Quicksand", sans-serif;
}

.garage-footer a {
  color: #007291;
  text-decoration: none;
}

/* --- Loader --- */
.loader-circle {
  width: 60px;
  height: 60px;
  border: 6px solid #ddd;
  border-top-color: #007291;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* --- Details Modal --- */
.details-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.details-modal-content {
  background: #fff;
  width: 90%;
  max-width: 500px;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  box-shadow: 0 2px 8px rgba(0,0,0,0.25);
  font-family: "Quicksand", sans-serif;
}

.details-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
}

.details-modal-content ul {
  list-style: disc;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
  font-family: "Quicksand", sans-serif;
}

.details-modal-content h4 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
}

.section-heading {
  font-family: "Quicksand", sans-serif;
}

.spec-label {
  font-family: "Quicksand", sans-serif;
}

@media (max-width: 600px) {
  .garage-wrapper {
    padding-bottom: 100px;
  }
}
