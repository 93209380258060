/* Outer layout wrapper */
.find-car-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fdfefe;
  min-height: 100vh;
  padding: 20px;
  font-family: "Quicksand", sans-serif;
}

/* The top row with step text, partial progress, skip link */
.top-row {
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: flex-end; /* If no back button on the left, we push to the right */
  margin-bottom: 20px;
  gap: 20px;
}

/* Step line in the center */
.step-line {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 16px;
  justify-content: center;
}

.step-text {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  font-family: "Quicksand", sans-serif;
}

.progress-container {
  flex: 1;
  height: 6px;
  background-color: #e0e0e0;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background-color: #007291; /* teal or ocean-blue fill */
  transition: width 0.3s ease;
}

.skip-btn {
  background: none;
  border: none;
  font-size: 16px;
  color: #666;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
}

/* Main question card */
.question-card {
  width: 100%;
  max-width: 700px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.1);
  padding: 24px;
  margin-bottom: 20px;
}

.question-card h2 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
  font-family: "Quicksand", sans-serif;
}

.question-card h3 {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin-bottom: 4px;
  font-family: "Quicksand", sans-serif;
}

.step-subtitle {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  font-family: "Quicksand", sans-serif;
}

/* New styling for required and optional labels */
.required,
.optional {
  color: #007291;
  font-weight: 600;
}

/* The container for big pill-like checkboxes or brand checkboxes */
.option-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}

/* narrower list for usage, mustHaves, seats, etc. */
.narrow-list {
  max-width: 400px; /* so they don't span the entire card */
}

/* wide-grid for brand, plus scrolling */
.wide-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 12px;
}

/* If brand list is huge, allow scrolling within the card area */
.brand-scroller {
  max-height: 300px; /* or 400px, up to you */
  overflow-y: auto;
}

/* Each tile: circle on left, text on the right */
.option-tile {
  position: relative;
  display: flex;
  align-items: center;
  padding: 14px 16px;
  background-color: #eef4f7; /* light bluish shade */
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.option-tile:hover {
  background-color: #e1edf3;
}

/* Hide the native checkbox/radio */
.option-tile input {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

/* The label text */
.option-tile span {
  font-size: 16px;
  color: #333;
  margin-left: 36px; /* space for the circle */
  flex: 1;
  font-family: "Quicksand", sans-serif;
}

/* Circle outline on the left with :has for the dot */
.option-tile::before {
  content: "";
  position: absolute;
  left: 16px;
  width: 20px;
  height: 20px;
  border: 2px solid #999;
  border-radius: 50%;
  background-color: transparent;
  transition: border-color 0.2s;
}

label.option-tile:has(input:checked)::before {
  border-color: #007291;
}
label.option-tile:has(input:checked)::after {
  content: "";
  position: absolute;
  left: 10px;
  top: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #007291;
}

/* nav row at bottom */
.nav-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.back-btn,
.next-btn {
  min-width: 80px;
  padding: 10px 16px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-family: "Quicksand", sans-serif;
}
.back-btn {
  background-color: #f7f7f7;
  color: #333;
}
.next-btn {
  background-color: #007291;
  color: #fff;
}

/* The disclaimers at the bottom */
.disclaimer-area {
  font-size: 14px;
  color: #666;
  text-align: center;
  max-width: 600px;
  margin-top: 40px;
  font-family: "Quicksand", sans-serif;
}
.disclaimer-area a {
  color: #007291;
  text-decoration: none;
}

/* Loading screen container */
.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh; /* Adjust as needed */
  text-align: center;
}

/* Header text above the loading bar */
.loading-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Quicksand", sans-serif !important;
}

.blue-text {
  color: #007291;
  font-family: "Quicksand", sans-serif !important;
}

/* Styling for the sub-header text */
.sub-header {
  font-size: 16px;
  margin-top: 10px;
  font-family: "Quicksand", sans-serif;
  color: #333;
}

/* Bubbling dots container */
.bubbling-dots {
  display: inline-block;
  margin-left: 5px;
}

/* Each dot will bubble */
.dot {
  display: inline-block;
  animation: bubble 1.5s infinite;
  font-weight: bold;
}

/* Stagger the animation for each dot */
.dot:nth-child(1) {
  animation-delay: 0s;
}
.dot:nth-child(2) {
  animation-delay: 0.3s;
}
.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes bubble {
  0%, 80%, 100% {
    transform: translateY(0);
    opacity: 0.3;
  }
  40% {
    transform: translateY(-10px);
    opacity: 1;
  }
}

/* The loading bar container */
.loading-bar {
  width: 80%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

/* The animated blue fill */
.loading-fill {
  height: 100%;
  background-color: #007291;
  width: 0%;
  animation: fillAnimation 2s infinite;
}

@keyframes fillAnimation {
  0% { width: 0%; }
  50% { width: 100%; }
  100% { width: 0%; }
}

/* Container for the moving car */
.car-container {
  width: 80%;
  position: relative;
  height: 50px;
  overflow: hidden;
}

/* The car image styling and animation */
.loading-car {
  width: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  animation: carAnimation 2s infinite;
}

@keyframes carAnimation {
  0% { left: 0%; }
  50% { left: 90%; }
  100% { left: 0%; }
}

/* New CSS for equal-width option container for questions where the longest text determines the bubble width */
.equal-width {
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
}
.equal-width .option-tile {
  width: 100%;
  min-width: max-content;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .equal-width .option-tile {
    min-width: 0;       /* Remove the forced minimum width */
    white-space: normal; /* Allow text wrapping */
  }
}
