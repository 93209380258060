.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: #fff;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
    z-index: 1000;
  }
  
  .nav-item {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Icon container: default black background; turns blue when active/focused */
  /* Default icon container is black */
.nav-icon-bg {
    background-color: #000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  /* When the parent link is active, focused, or hovered, change the background color */
  .nav-item:active .nav-icon-bg,
  .nav-item:focus .nav-icon-bg,
  .nav-item:hover .nav-icon-bg {
    background-color: #007291;
  }
  
  /* Icon styling */
  .nav-icon {
    width: 18px;
    height: 18px;
    object-fit: contain;
    /* For SVG icons that inherit color, you can set:
       color: #fff; */
  }
  
  /* Hide bottom nav on larger screens */
  @media (min-width: 769px) {
    .bottom-nav {
      display: none;
    }
  }
  
  /* Add padding to body so content isn't hidden behind nav */
  body {
    padding-bottom: 60px;
    box-sizing: border-box;
  }
  