/* Footer.css */

/* Root container for the Footer */
.home-footer {
    background-color: #000;       /* Black background */
    color: #fff;                  /* White text */
    padding: 20px 40px;
    font-family: "Quicksand", sans-serif;
  }
  
  /* Container that holds both left and right groups in one row */
  .footer-container {
    display: flex;
    justify-content: space-between;  /* Space between left and right groups */
    align-items: center;
    flex-wrap: wrap;                 /* Allow wrapping on smaller screens */
  }
  
  /* Left group styling */
  .footer-left {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .footer-logo {
    width: 60px;  /* Adjust size as needed */
    height: auto;
  }
  
  .footer-brand {
    font-size: 20px;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
  }
  
  .footer-copy {
    font-size: 14px;
    color: #ccc;
    font-family: "Quicksand", sans-serif;
  }
  
  /* Right group styling */
  .footer-right {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .footer-link {
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    transition: color 0.3s;
    font-family: "Quicksand", sans-serif;
  }
  
  .footer-link:hover {
    color: #007291; /* Ocean blue on hover */
  }
  
  /* Responsive adjustments for small screens */
  @media (max-width: 600px) {
    .footer-container {
      flex-direction: column;
      gap: 20px;
    }
    .footer-left,
    .footer-right {
      justify-content: center;
    }
  }
  