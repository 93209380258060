.landing-page {
  background-color: #fdfeff;
  width: 100%;
  margin: 0 auto;
  /* Option 1: Using child margin */
  /* .landing-page > * + * {
       margin-top: 8rem;
     } */
     
  /* Option 2: Using flex gap */
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.back-to-top {
  margin: 3rem auto;
  display: block;
  padding: 1rem 2rem;
  font-size: 1rem;
  background-color: var(--ocean-blue);
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .landing-page {
    gap: 0;
  }
  .landing-page > * {
    margin: 0; /* Reset any margins on the children */
    padding: 0; /* If necessary, reset padding as well */
  }
}

