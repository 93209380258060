
.askqarby-sidebar {
    width: 200px;
    background-color: #f4f4f4; /* Light gray instead of black */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    box-sizing: border-box;
    color: #000000; /* Black text for contrast */
}

.askqarby-sidebar .brand {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.ios-back-button {
    color: #000000;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    margin-top: 10px;
}
.ios-back-button:hover {
    background-color: #e0e0e0;
}

.askqarby-chat-interface {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    box-sizing: border-box;
    border-left: 5px solid black;
    padding: 20px;
    overflow: hidden;
    font-family: "Quicksand", sans-serif;
}

.askqarby-chat-interface h3 {
    margin: 0 0 20px;
    font-size: 1.5rem;
    font-family: "Quicksand", sans-serif;
}

.car-selection {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}
.car-select {
    flex: 1;
}

/* Messages */
.messages {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 15px; /* Space between messages and chat-input */
}

/* User messages on the right */
.user-message {
    align-self: flex-end;
    background-color: #2b6cb0; /* Blue */
    color: white;
    padding: 10px 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    max-width: 80%;
    word-wrap: break-word;
}

/* Assistant messages on the left */
.assistant-message {
    align-self: flex-start;
    background-color: #edf2f7; /* Light gray */
    color: black;
    padding: 10px 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    max-width: 80%;
    word-wrap: break-word;
    font-family: "Quicksand", sans-serif;
}

/* "Typing..." style */
.assistant-message.typing {
    background-color: #edf2f7; 
}
.typing-indicator {
    font-style: italic;
    color: #cbd5e0;
    font-family: "Quicksand", sans-serif;
}

/* Chat input row */
.chat-input {
    display: flex;
    gap: 10px;
    background-color: #f9f9f9;
    padding: 10px 0;
}
.chat-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #e2e8f0;
    border-radius: 5px;
}
.chat-input button {
    padding: 10px 20px;
    background-color: #2b6cb0;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.chat-input button:hover {
    background-color: #2c5282;
}

/* Global font smoothing / family */
* {
    font-family: "Quicksand", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* ----------------------------------- */
/* Tablet styles: ~601px to 1024px     */
/* ----------------------------------- */
@media (min-width: 601px) and (max-width: 1024px) {
    /* Slightly shrink sidebar for tablets */
    .askqarby-sidebar {
        width: 160px;
        padding: 15px;
    }
    .askqarby-sidebar .brand {
        font-size: 16px;
        margin-bottom: 15px;
    }

    /* Make border smaller or different color if you want */
    .askqarby-chat-interface {
        border-left: 3px solid black;
        padding: 15px;
    }

    /* Possibly reduce gap in .car-selection */
    .car-selection {
        gap: 8px;
    }
    .car-select {
        flex: unset;
        width: 120px;
    }

    .chat-input {
        gap: 8px;
        padding: 8px 0;
    }
    .chat-input input {
        padding: 8px;
    }
    .chat-input button {
        padding: 8px 16px;
        font-size: 14px;
    }

    /* Tweak message bubble spacing */
    .messages {
        padding: 10px;
        margin-bottom: 10px;
    }
    .user-message, .assistant-message {
        margin-bottom: 8px;
        font-size: 0.95rem;
    }
}

/* ----------------------------------- */
/* Mobile: up to 600px width           */
/* ----------------------------------- */
@media (max-width: 600px) {

    .askqarby-sidebar {
        width: 100%;
        border-right: none;
        border-bottom: 5px solid black;
        padding: 10px;
    }

    .askqarby-chat-interface {
        border-left: none;
        border-top: none;  
        padding: 10px; 
        height: auto; 
        overflow: visible; 
    }

    .askqarby-wrapper {
        font-family: "Quicksand", sans-serif; 
    }

   /* Ensure the input itself uses Quicksand */

  


    .car-selection {
        flex-direction: column;
        gap: 10px;
    }
    .car-select {
        width: 100%;
    }

    .messages {
        max-height: 50vh; 
        overflow-y: auto;
        margin-bottom: 10px;
    }

    .askqarby-sidebar .brand {
        font-size: 16px;
    }
    .ios-back-button {
        font-size: 12px;
        padding: 5px;
    }

    .chat-input {
        flex-direction: column;
        gap: 10px;
    }
    .chat-input input {
        padding: 8px;
    }
    .chat-input button {
        width: 100%;
        padding: 8px 10px;
        font-size: 14px;
    }
}
